<template>
    <section class="cards">
        <div class="cards-wrapper">
            <CommonBlock
                :title="title"
                :visible-back-btn="false"
                :breadcrumbs="breadcrumbs"
                @clickBack="clickBack"
            >
                <div class="filter">
                    <form class="filter-form" @submit.prevent="">
                        <div class="filter-top">
                            <h3 class="filter-title">Фільтри
                                <button type="button" class="filter-reset" @click="resetClick" v-if="showReset">
                                    <span class="filter-reset_icon">
                                        <img src="../assets/images/reset.svg" alt="reset">
                                    </span>
                                </button>
                            </h3>
<!--                            <button type="button" class="trigger-map current" @click="visibleCard = !visibleCard">-->
<!--                                <span v-if="!visibleCard">На мапі</span>-->
<!--                                <span v-else>Списком</span>-->
<!--                            </button>-->
                        </div>

                        <div class="input-field">
                            <input
                                v-model="form.first_name"
                                autocomplete="off"
                                :placeholder="'Ім\'я'"
                                :maxlength="20"
                            />
                        </div>
                        <div class="input-field">
                            <input
                                v-model="form.last_name"
                                autocomplete="off"
                                :placeholder="'Прізвище'"
                                :maxlength="20"
                            />
                        </div>
                        <div class="input-field">
                            <input
                                v-model="form.middle_name"
                                autocomplete="off"
                                :placeholder="'По батькові'"
                                :maxlength="20"
                            />
                        </div>
                        <CustomSelectV2
                            v-if="namePage === 'cards'"
                            v-for="(item, index) in dropdowns"
                            :placeholder="item.placeholder"
                            :key="index"
                            v-model="form[item.name]"
                            :noOptions="item.noOptions"
                            :searchable="item.searchable"
                            :options="item.options"
                            class="select"
                        />
                        <div v-if="namePage === 'cards-participant'">
                            <CustomSelectV2
                                class="select"
                                placeholder="Вид діяльності"
                                :options="activityList"
                                name="job_type_id"
                                v-model="form.job_type_id"
                            />
                            <CustomSelectV2
                                class="select"
                                placeholder="Партійна організація"
                                :options="partyOrganizationList"
                                name="party_organization_id"
                                v-model="form.party_organization_id"
                                searchable="true"
                            />
                        </div>
                        <div class="filter-actions">
                            <button type="button" @click="clickBack" class="btn-back">
                                <span class="btn-back_icon">
                                <img src="../assets/images/btn-back.svg" alt="back"/>
                                </span>
                                Назад
                            </button>
                            <div style="position:relative;">

                                <CustomButton label="Застосувати" @click="search"/>
                            </div>
                        </div>
                    </form>
                </div>
            </CommonBlock>

<!--            <Map v-if="visibleCard" @select_district="select_district"/>-->
            <deputy-cards ref="deputyCards" />
        </div>
    </section>
</template>


<script>
    import CustomFilterSelect from '../components/deputies/CustomFilterSelect'
    import CustomButton from "../components/CustomButton";
    import DeputyCardItem from "../components/DeputyCardItem";
    import CommonBlock from "../components/CommonBlock";
    import DeputyCards from "../components/deputies/DeputyCards";
    import InputText from "../components/ui/InputText";
    import UserService from "../services/UserService";
    import Map from "../components/map/Map";
    import CouncilService from "../services/CouncilService";
    import CustomSelectV2 from "@/components/ui/CustomSelectV2";

    export default {
        components: {
            DeputyCards,
            CustomFilterSelect,
            Map,
            CustomButton,
            DeputyCardItem,
            CustomSelectV2,
            CommonBlock,
            InputText,
        },
        data() {
            return {
                breadcrumbs: [],
                cards: [],
                visibleCard: true,
                activityList: [],
                partyOrganizationList: [],
                dropdowns: [
                    {
                        name: 'area_id',
                        placeholder: 'Назва населеного пункту',
                        options: [],
                        noOptions: 'Населеного пункту ради не знайдено',
                        searchable: false
                    },
                    {
                        name: 'rada_id',
                        placeholder: 'Назва ради',
                        searchable: true,
                        noOptions: 'Назву ради не знайдено',
                        options: [],
                    },
                    {
                        name: 'cadence',
                        placeholder: 'Каденція',
                        searchable: false,
                        noOptions: 'Каденцію не знайдено',
                        options: [],
                    },
                    {
                        name: 'commission',
                        searchable: false,
                        placeholder: 'Комісія',
                        noOptions: 'Комісію не знайдено',
                        options: [],
                    },
                    {
                        name: 'rada_position',
                        placeholder: 'Посада в раді',
                        searchable: false,
                        noOptions: 'Посаду в раді не знайдено',
                        options: [],
                    }
                ],
                showReset: false,
                form: {
                    district_id: '',
                    first_name: "",
                    last_name: "",
                    middle_name: "",
                    area_id: "",
                    rada_id: "",
                    cadence: "",
                    commission: "",
                    rada_position: "",
                    job_type_id: "",
                    party_organization_id: ""
                },
                title: '',
                namePage: ''
            };
        },
        watch: {
            async visibleCard() {
                let query = '?';
                if(!this.visibleCard) {
                    query = `?visibleCard=${this.visibleCard}`
                    await this.search();
                }
                window.history.replaceState(null, null, query);
            }
        },
        mounted() {
            if(!this.visibleCard) {
                this.search();
            }
        },
        created() {
            this.visibleCard = this.$route.query.visibleCard === 'false' ? false : this.visibleCard;
            this.dropdowns.forEach((_, index) => {
                this.getOptions(index)
            });

            this.namePage = this.$route.name

            if (this.namePage === 'cards'){
                this.title = 'Картки депутатів'
            } else if (this.namePage === 'cards-participant'){
                this.title = 'Картки партійців'
            }

            this.getActivityList()
            this.getOrganizationList()

        },
        methods: {
            select_district(district_id) {
                this.form.district_id = district_id;
                this.visibleCard = false;
                setTimeout(() => {
                    this.search();
                });
            },
            clickBack() {
                this.$router.go(-1);
            },
            async getOptions(id) {
                let dropdown = this.dropdowns[id];
                let options = [];
                if(dropdown.name === 'rada_id')
                    options = await CouncilService.get();
                else {
                    options = await UserService.getFilter(dropdown.name);
                }

                dropdown.options = options.map(option => {
                    return {
                        label: option.value,
                        value: option.key
                    }
                });
            },
            async resetClick() {
                this.form = {
                    district_id: '',
                    first_name: "",
                    last_name: "",
                    middle_name: "",
                    area_id: "",
                    rada_id: "",
                    cadence: "",
                    commission: "",
                    rada_position: "",
                    job_type_id: "",
                    party_organization_id: ""
                };

                this.showReset = false;

                await this.search();
            },
            async search() {
                this.visibleCard = false;
                let searchParams = {};
                for (const [key, value] of Object.entries(this.form)) {
                    if(value !== "") {
                        this.showReset = true;
                        searchParams[key] = value;
                    }
                }

                if(!this.visibleCard) {
                    setTimeout(() => {
                        this.$refs.deputyCards.searchDeputies(searchParams);
                    });
                }
            },
            async getActivityList(){
                let response = await UserService.getActivityList()
                response.forEach(item => {
                    this.activityList.push(
                        {"label": item.name, 'value': item.id}
                    )
                })
            },
            async getOrganizationList(){
                let response = await UserService.getPartyOrganization()
                response.forEach(item => {
                    this.partyOrganizationList.push(
                        {"label": item.name, 'value': item.id}
                    )
                })
            }
        }
    };
</script>


<style lang="less">
    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
        opacity: 0;
    }
    .select{
        margin-bottom: 20px;
        .custom-select-v2 .vs__dropdown-toggle{
            background-color: rgba(205, 212, 224, 0.4);
            border: 0;
            input{
                color: #fff;
                font-size: 18px;
            }
        }
        .custom-select-v2 .vs__selected{
            color: #fff;
            font-size: 18px;
        }
        .vs__dropdown-menu{
            border: 0;
            border-radius: 0 0 15px 15px;
            background-color: rgba(205, 212, 224, 0.9);
            color: #1a2744;
            min-height: 50px;
        }
    }
    .cards {
        background-color: #F2F2F2;
        height: 100vh;
        @media screen and (max-width: 768px) {
            height: auto;
        }
        &-wrapper {
            display: flex;
            height: 100vh;
            @media screen and (max-width: 768px) {
                display: block;
                height: auto;
            }
        }

        &-inner {
            width: 30%;
            height: 100vh;
            background: url(../assets/images/calendar-bg.jpg) no-repeat center;
            background-size: cover;
            padding: 80px 51px 80px 80px;
            overflow-y: auto;
        }

        &-title {
            font-weight: bold;
            font-size: 56px;
            line-height: 64px;
            color: #FFFFFF;
            margin-bottom: 40px;
            padding-bottom: 40px;
        }

        &-items {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
        }

        &-item {
            max-width: 595px;
            width: 32%;
            background: #FFFFFF;
            padding: 20px;
            margin-right: 10px;
            margin-bottom: 10px;
            cursor: pointer;
            position: relative;
            display: flex;
            text-decoration: none;

            &:hover {
                transform: scale(1.01);
                box-shadow: 0 24px 24px -14px rgb(249 198 35 / 35%);
            }

            &_avatar {
                min-width: 70px;
                max-width: 70px;
                height: 70px;
                margin-right: 20px;
                width: 30%;

                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                    object-fit: cover;
                }
            }

            &_inner {
                display: flex;
                justify-content: space-between;
                width: 100%;
            }
            &_info {
                max-width: 470px;
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                word-break: break-word;
            }
            &_name {
                font-weight: bold;
                font-size: 20px;
                line-height: 28px;
                letter-spacing: -0.14px;
                color: #1A2744;
            }

            &_location {
                font-weight: normal;
                font-size: 16px;
                line-height: 24px;
                color: #1A2744;
                margin-bottom: 8px;
            }

            .positions {
            /*    border-bottom: 1px solid #F5F5F5;
                margin-bottom: 20px;*/
            }

            .position {
                font-weight: normal;
                color: #1A2744;
                font-size: 13px;
                line-height: 20px;
                letter-spacing: -0.091px;
                margin-bottom: 10px;
            }

            &_phone {
                font-weight: normal;
                font-size: 18px;
                line-height: 24px;
                color: #1A2744;
                padding-top: 20px;
                border-top: 1px solid #F5F5F5;
                margin-top: auto;
            }
        }
    }

    .filter {
        &-top {
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
        }

        .trigger-map{
            font-size: 20px;
            font-weight: bold;
            line-height: 1.2;
            text-align: right;
            span{
                color: #ffc900;
                transition: color .15s linear;
            }
        }

        &-form {
            max-width: 480px;
        }

        &-title {
            position: relative;
            font-weight: bold;
            font-size: 32px;
            line-height: 40px;
            letter-spacing: -0.224px;
            color: #FFC900;
        }

        &-reset {
            display: flex;
            align-items: center;
            font-weight: bold;
            font-size: 18px;
            right: -40px;
            line-height: 24px;
            text-align: right;
            color: #fff;
            position: absolute;
            bottom: 7px;
            &_icon {
                width: 20px;
                height: 20px;
                margin-right: 6px;
            }
        }
        label {
            display: block;
            margin-bottom: 6px;
            font-weight: bold;
            font-size: 12px;
            line-height: 16px;
            color: #CDD4E0;
            padding-left: 20px;
        }

        &-group {
            margin-bottom: 20px;
        }

        &-actions {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 50px;
        }

        .custom-button {
            width: 220px;
            height: 56px;
            margin-right: -15px;

            &:after {
                margin-left: -85px;
                top: 10%;
                width: 180px;
                height: 36px;
            }
        }
    }

    .content {
        padding: 80px 40px;
        width: 70%;
        height: 100vh;
        overflow-y: auto;

        &-top {
            border-bottom: 1px solid rgba(0, 0, 0, 0.15);
            padding-bottom: 20px;
            margin-bottom: 20px;
        }

        &_main-title {
            font-weight: bold;
            font-size: 32px;
            line-height: 40px;
            color: #1A2744;
            margin-bottom: 10px;
        }

        &-description {
            font-weight: normal;
            font-size: 20px;
            line-height: 24px;
            letter-spacing: -0.14px;
            color: #1A2744;
        }
    }

    .cards .content{
        @media all and(max-width: 768px) {
            padding-top: 40px;
            padding-left: 20px;
            padding-right: 20px;
            height: auto;
        }
    }
    .cards {
        @media all and(max-width: 768px) {
            .title{
                display: none;
            }
            .filter-actions{
                justify-content: center;
                button{
                    margin: 0;
                }
            }
            .slot{
                margin-bottom: 40px;
                margin-top: 40px;
            }
            .cards-items{
                display: block;
                .cards-item{
                    max-height: 100%;
                    .cards-item_inner{
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                    }
                    .cards-item_location{
                        text-align: center;
                    }
                    .cards-item_name{
                        text-align: center;
                    }
                    .cards-item_phone{
                        text-align: center;
                    }
                }
            }
        }
    }

    .header {
        width: 100%;
    }

    .header-logo {
        // width: 253px;
        height: 51px;
        margin-bottom: 22px;
        display: inline-block;

        img {
            width: 100%;
            height: 100%;
        }
    }

    /* breadcrumb */
    .breadcrumb {
        list-style: none;
        margin-bottom: 45px;
    }

    .breadcrumb-item {
        display: inline;
        font-size: 16px;
        line-height: 30px;
        letter-spacing: -0.112px;
        color: #fff;
        margin-right: 20px;
    }

    .breadcrumb-link {
        font-size: 16px;
        line-height: 30px;
        letter-spacing: -0.112px;
        color: #ffc900;
        text-decoration: none;
        position: relative;

        &::after {
            position: absolute;
            content: '';
            width: 1px;
            height: 15px;
            background: #fff;
            bottom: 1px;
            right: -11px;
        }
    }

    .btn-back {
        display: flex;
        align-items: center;
        font-weight: normal;
        font-size: 20px;
        line-height: 24px;
        color: #ffc900;
        text-decoration: none;
        margin-right: 15px;

        &_icon {
            width: 56px;
            height: 56px;
            border-radius: 50%;
            background: #ffc900;
            box-shadow: 0 24px 24px -14px rgba(249, 198, 35, 0.35);
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 20px;
        }
    }

    /* FITLER INPUTS */
    .input-field {
        margin-bottom: 20px;
        label {
            display: block;
            font-weight: bold;
            font-size: 12px;
            line-height: 16px;
            color: #1A2744;
            padding-left: 20px;
            margin-bottom: 6px;
        }
        input {
            height: 56px;
            max-width: 480px;
            width: 100%;
            outline: none;
            font-weight: normal;
            font-size: 18px;
            line-height: 24px;
            cursor: text;
            border: none;
            border-radius: 15px;
            padding-left: 20px;
            color: #F5F5F5;
            background: rgba(205, 212, 224, .4);
            position: relative;
            &::placeholder {
                color: #F5F5F5;
            }
            &::before {
                position: absolute;
                content: "";
                top: 8px;
                right: 50px;
                width: 1px;
                height: 40px;
                background: #9DA3AE;
            }
        }
    }

    .error-label{
        position: absolute;
        top: 100%;
        right: 0;
        color: #ffc900;
        font-size: 13px;
        text-align: right;
    }

    input:disabled {
        color: #9DA3AE;
        background-color: #fff;
        border: 1px solid #CDD4E0;
    }
    .input-field .disable-input {
        color: #9DA3AE;
        background-color: #fff;
        border: 1px solid #CDD4E0  !important;
    }

    @media screen and (max-width: 1920px) {
        .content {
            max-width: 1280px;
            width: 70%;
        }

        .cards-inner {
            max-width: 640px;
            width: 30%;
        }

        .cards-items {
            justify-content: space-between;
        }

        .cards-item {
            max-width: 592px;
            width: 49%;
            margin-right: 0;
        }
    }

    @media screen and (max-width: 1600px) {
        .content {
            max-width: 1280px;
            width: 70%;
            padding: 80px 30px 40px 30px;
        }

        .cards-inner {
            padding: 40px 40px 40px 50px;
        }
    }

    @media screen and (max-width: 1440px) {
        .cards-title {
            font-size: 30px;
            line-height: 40px;
            margin-bottom: 20px;
        }

        .filter-title {
            font-size: 20px;
            line-height: 24px;
        }

        .filter-reset {
            font-size: 14px;
            line-height: 18px;
            bottom: 0;
        }

        .cards-item {
            padding: 15px;
        }
    }
    @media screen and (max-width: 1280px) {
        .positions {
            margin-bottom: 12px;
        }
    }
    @media screen and (max-width: 1200px) {

        .cards {
            .content {
                width: 50%;
                max-width: 50%;
            }
            .common-block {
                max-width: 50% ;
                width: 50%;
            }
        }
        .cards-item {
            max-width: 100% ;
            width: 100%;
        }

    }
    @media screen and (max-width: 768px) {
        .cards {
            .content {
                width: 100%;
                max-width: 100%;
            }
            .common-block {
                width: 100%;
                max-width: 100%;
            }
        }
        .cards-item {
            max-width: 100%;
            width: 100%;
        }

    }

    @media screen and (max-height: 700px) {

    }

</style>


<style scoped lang="less">
    .overlay {
        position: fixed;
        z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
    }

    .modal {
        position: absolute;
        width: 640px;
        height: 100vh;
        z-index: 9999;
        padding: 80px;
        background-color: #fff;
        right: 0;
        top: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow-y: auto;

        &-close {
            position: absolute;
            top: 4px;
            right: 0;
            width: 26px;
            height: 26px;

            &:hover {
                transform: rotate(90deg);
            }
        }

        &-header {
            border-bottom: 1px solid rgba(0, 0, 0, 0.15);
            padding-bottom: 20px;
            // margin-bottom: 20px;
            position: relative;
        }

        &-title {
            font-weight: bold;
            font-size: 32px;
            line-height: 40px;
            color: #1A2744;
            margin-bottom: 10px;
            position: relative;

            &::before {
                position: absolute;
                content: '';
                height: 30px;
                width: 23px;
                top: 4px;
                left: -40px;
                background: url(../assets/images/document.svg) no-repeat center;
                background-size: contain;
            }
        }

        &-description {
            font-weight: normal;
            font-size: 20px;
            line-height: 24px;
            letter-spacing: -0.14px;
            color: #1A2744;
        }
    }

    ::v-deep .yellow-color {}

    @media screen and (max-height: 900px) {
        // .modal {
        //   padding: 30px;
        // }
        .modal-card_item {
            padding: 15px 0;
        }
    }
</style>
