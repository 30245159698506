<template>
    <div class="filter-group">
        <div class="custom-select" :tabindex="tabindex" @blur="open = false">
            <div class="selected" :class="{ open: open }" @click="onOpen">
                {{ selected }}
            </div>
            <div class="items" :class="{ selectHide: !open }">
                <div
                    v-for="(option, i) of options"
                    :key="i"
                    @click="onClick(option)"
                >
                    {{ option.value }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import UserService from "../../services/UserService";

    export default {
        props: {
            options: {
                type: Array,
                required: true
            },
            placeholder: {
                type: String,
                required: true
            },
            tabindex: {
                type: Number,
                required: false,
                default: 0,
            }
        },
        data() {
            return {
                selected: this.placeholder,
                initialized: false,
                open: false
            };
        },
        methods: {
            async onOpen() {
                if(!this.initialized) {
                    this.$emit('optionsUpdate');
                    this.initialized = true;
                }
                this.open = !this.open;
            },
            onClick(option) {
                this.selected = option.value;
                this.open = false;
                this.$emit('input', option.key);
            },
            reset() {
                this.selected = this.placeholder;
                this.open = false;
                this.$emit('input', '');
            }
        }
    };
</script>

<style scoped>
    .custom-select {
        position: relative;
        width: 100%;
        outline: none;
        line-height: 56px;
        height: 56px;
        border-radius: 15px;
        box-sizing: border-box;
    }

    .custom-select .selected {
        cursor: pointer;
        border: none;
        border-radius: 15px;
        border: none;
        padding-left: 20px;
        font-weight: normal;
        font-size: 18px;
        color: #F5F5F5;
        background: rgba(205, 212, 224, .4);
    }

    .custom-select .selected.open {
        border: 1px solid #FFC900;
        border-radius: 15px 15px 15px 15px;
        background-color: #FFC900;
        color: #1A2744;
    }

    .custom-select .selected:after {
        position: absolute;
        content: "";
        top: 18px;
        right: 18px;
        width: 11.38px;
        height: 20px;
        background: url(../../assets/images/select.svg) no-repeat center;
        background-size: contain;
    }

    .custom-select .selected.open:after {
        background: url(../../assets/images/select-open.svg) no-repeat center;
    }

    .custom-select .selected::before {
        position: absolute;
        content: "";
        top: 8px;
        right: 50px;
        width: 1px;
        height: 40px;
        background: #9DA3AE;

    }

    .custom-select .selected.open::before {
        background: #fff;
    }

    .custom-select .items {
        color: #fff;
        border-radius: 15px 15px 15px 15px;
        overflow: hidden;
        border-right: 1px solid #FFC900;
        border-left: 1px solid #FFC900;
        border-bottom: 1px solid #FFC900;
        position: absolute;
        background-color: #FFC900;
        left: 0;
        right: 0;
        top: 70px;
        z-index: 1;
        max-height: 336px;
        overflow-y: auto;
    }

    .custom-select .items div {
        color: #fff;
        padding-left: 20px;
        line-height: 38px;
        height: 38px;
        cursor: pointer;
        user-select: none;
    }

    .custom-select .items div:hover {
        color: #1A2744;
        background: rgba(255, 255, 255, 0.5);
    }

    .selectHide {
        display: none;
    }
</style>
