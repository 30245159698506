<template>
    <router-link
        class="cards-item"
        :to="url"
        >
        <div class="cards-item_inner">
            <div class="achievement-list" v-if="pageName === 'cards-participant'">
                <div class="achievement">
                    <div class="image-container" v-for="award in user.awards">
                        <img v-if="+award.system_id === 1" :title="award.name" src="../../assets/images/bee_1.svg" :alt="award.name">
                        <img v-if="+award.system_id === 2" :title="award.name" src="../../assets/images/bee_2.svg" :alt="award.name">
                        <img v-if="+award.system_id === 3" :title="award.name" src="../../assets/images/bee_3.svg" :alt="award.name">
                        <img v-if="+award.system_id === 4" :title="award.name" src="../../assets/images/bee_4.svg" :alt="award.name">
                    </div>
                </div>
            </div>
            <div class="cards-item_avatar">
                <img v-if="user.photo" :src="user.photo"/>
                <img v-if="+user.gender === 0 && !user.photo" src="@/assets/images/icon-male.svg" alt="" srcset="">
                <img v-else-if="+user.gender === 1 && !user.photo" src="@/assets/images/icon-female.svg" alt="" srcset="">
            </div>
            <div class="cards-item_info">
                <h4 class="cards-item_name">{{ fullName }}</h4>
                <p v-if="user.area"  class="cards-item_location">{{ user.area }}</p>
                <div v-if="isDeputy && (user.rada_position || user.commission)" class="positions">
                    <p v-if="user.rada_position" class="position head">{{ user.rada_position }}</p>
                    <p v-if="user.commission" class="position comission">{{ user.commission }}</p>
                </div>
                <div v-if="isDeputy && cardParticipant" class="positions">
                    <p class="party-organization" v-if="user.party_organization">{{user.party_organization}}</p>
                    <p class="job-type" v-if="user.job_type">{{user.job_type}}</p>
                </div>
                <div class="cards-item_phone">{{ user.phone }}</div>
            </div>
        </div>
    </router-link>
</template>

<script>
    export default {
        name: "DeputyCard",
        props: {
            user: {
                type: Object,
                required: true
            },
            cardParticipant: {
                type: Boolean,
                required: true
            }
        },
        data () {
            return{
                participantPage: false,
                pageName: '',
                achievementList: [
                    {title: 'Я — активний учасник партійних заходів', achievementStatus: 2},
                    {title: 'Я —  медійно активний', achievementStatus: 3},
                    {title: 'Я реалізую партійні проекти', achievementStatus: 4},
                ],
                isDeputy: this.$store.getters.identity.is_deputy,
            }
        },
        computed: {
            fullName() {
                return this.user.last_name + " " + this.user.first_name + " " + this.user.middle_name;
            },
            url() {
                if (!this.cardParticipant){
                    return '/card/' + this.user.id;
                } else {
                    return '/detail-participant/' + this.user.id;
                }
            }
        },
        created() {
            if(this.user.awards.length) {
                console.log(this.user);
            }
            this.pageName = this.$route.name
        }
    }
</script>

<style scoped lang="less">
    .position {
        padding-left: 30px;
    }
    .head {
        position: relative;
        &:before {
            position: absolute;
            content: '';
            top: 4px;
            left: 0;
            width: 16px;
            height: 13.97px;
            background: url(../../assets/images/head.svg) no-repeat center center;
        }
    }
    .comission {
        position: relative;
        &:before {
            position: absolute;
            content: '';
            top: 4px;
            left: 0;
            width: 16px;
            height: 13.97px;
            background: url(../../assets/images/comission.svg) no-repeat center center;
        }
    }
    .party-organization, .job-type{
        font-size: 13px;
        line-height: 1.54;
        letter-spacing: -0.09px;
        color: #1a2744;
        position: relative;
        padding-left: 30px;
        padding-bottom: 10px;
        &:before{
            position: absolute;
            content: '';
            top: 4px;
            left: 0;
            width: 16px;
            height: 13.97px;
            background: url(../../assets/images/organization.svg) no-repeat center center;
        }
    }
    .job-type:before{
        background: url(../../assets/images/activity.svg) no-repeat center center;
    }

    .cards-item_inner{
        position: relative;
        .achievement-list{
            .achievement{
                position: absolute;
                top: 85px;
                left: 21px;
                .image-container{
                    img{
                        height: 25px;
                    }
                }
            }
        }
    }

</style>
