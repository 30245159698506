<template>
    <div class="input-field">
        <label>{{label}}</label>
        <input
            :type="type"
			v-bind:class="{isfocus:isFocus}"
            v-model="inputValue"
            autocomplete="off"
			:placeholder="placeholder"
			:disabled="disabled !== false"
			:maxlength="maxlength"
			:minlength="minlength"
		/>
        <span class="error-label" v-if="error">{{error.label}}</span>
    </div>
</template>


<script>
export default {
	props: {
		value: {
            default: "",
			type: String
		},
		placeholder: {
			default: "",
			type: String
		},
        label: {
			default: "",
			type: String
		},
        error: {
            default: "",
			type: String,
            required: false,
        },
		clearable: {
			default: false
		},
		disabled: {
			default: false
		},
		type: {
			default: "text",
            required: false,
		},
	},
	data() {
		return {
			inputValue: "",
            label: '',
			isFocus: false,
			disabled: false,
		};
	},
	methods: {
		clear() {
			this.inputValue = "";
			this.$emit("update:value", this.inputValue);
		},
		changes() {
			if (this.disabled == undefined) {
				this.isFocus = !this.isFocus;
			} else {
				if (this.disabled !== false) {
					this.isFocus = false;
				} else {
					this.isFocus = !this.isFocus;
				}
			}
		},
		documentClick(e) {
			try {
				if (this.$refs.input.contains(e.target)) return;
				this.isFocus = false;
			} catch (e) {
				console.log(e);
			}
		}
	},
	watch: {
		inputValue(newVal) {
			this.$emit("update:value", newVal);
		},
		value() {
			if (this.inputValue !== this.value) this.inputValue = this.value;
		}
	},
	created() {
		this.inputValue = this.value;
	},
	mounted() {
		document.addEventListener("click", this.documentClick);
	},
	beforeDestroy() {
		document.removeEventListener("click", this.documentClick);
	}
};
</script>


<style lang="less">
    .input-field {
	 margin-bottom: 20px;
        label {
            display: block;
            font-weight: bold;
            font-size: 12px;
            line-height: 16px;
            color: #1A2744;
            padding-left: 20px;
            margin-bottom: 6px;
        }
        input {
            height: 56px;
            max-width: 480px;
            width: 100%;
            outline: none;
            font-weight: normal;
            font-size: 18px;
            line-height: 24px;
			cursor: text;
			border: none;
			border-radius: 15px;
			padding-left: 20px;
			color: #F5F5F5;
			background: rgba(205, 212, 224, .4);
			position: relative;
            &::placeholder {
                color: #F5F5F5;
            }
			&::before {
				position: absolute;
				content: "";
				top: 8px;
				right: 50px;
				width: 1px;
				height: 40px;
				background: #9DA3AE;
			}
        }
    }

    .error-label{
        position: absolute;
        top: 100%;
        right: 0;
        color: #ffc900;
        font-size: 13px;
        text-align: right;
    }

	input:disabled {
       color: #9DA3AE;
       background-color: #fff;
       border: 1px solid #CDD4E0;
    }
    .input-field .disable-input {
        color: #9DA3AE;
        background-color: #fff;
        border: 1px solid #CDD4E0  !important;
    }
</style>
