<template>
    <div class="map">

        <gmap-map
            :center="center"
            :zoom="zoom"
            :options="options"
            ref="mapRef"
            style="width:100%; height:100%;"
        >
        </gmap-map>
        <transition name="sidebar-slide">
            <div class="sidebar" v-if="visibleSidebar">
                <ul>
                    <li v-for="region in stats" :key="region.id">
                        <button
                            @click="selectRegion(region.id)"
                            @mouseover="regionMouseOver(region.id)"
                            @mouseout="regionMouseOut(region.id)"
                        >{{region.text}}</button>
                        <strong>{{region.deputies_count}}</strong>
                    </li>
                </ul>
            </div>
        </transition>
    </div>
</template>

<script>
import RegionService from "@/services/RegionService";
import * as VueGoogleMaps from 'vue2-google-maps';
import DistrictService from "@/services/DistrictService";

let markers = [];
export default {
    name: "Map",
    data() {
        return {
            map: null,
            visibleSidebar: true,
            visiblePreloader: true,
            center: { lat: 48.7695975, lng:34.430653 },
            zoom: this.getCurrentZoom(),
            polygons: [],
            options: {
                disableDefaultUI: true,
                fullscreenControl: false,
                draggable: false,
                zoomControl: false,
                scrollwheel: false,
                disableDoubleClickZoom: true,
                minZoom: 4,
                maxZoom: 13,
            },
            infoWindow: null,
            regions: [],
            dataLayer: null,
            is_district: false,
            deputiesStats: [],
            coordinates: []
        }
    },
    created() {
        RegionService.get([1]).then(regions => {
            this.regions = regions.results;
            RegionService.deputiesCount().then(resp => {
                this.deputiesStats = resp;
                RegionService.coordinates().then(coordinates => {
                    this.coordinates = coordinates;
                    this.drawPolygons(this.prepareData(this.coordinates));
                });
            });
        });
    },
    mounted() {
        this.$refs.mapRef.$mapPromise.then((map) => {
            this.map = map;
            this.init();
        });
    },
    computed: {
        google: VueGoogleMaps.gmapApi,
        stats() {
            let data = [];
            this.regions.forEach(region => {
                let stat = this.getStatsByRegionId(region.id);
                if(!stat) {
                    return false;
                }

                region.deputies_count = stat.deputies_count;
                data.push(region);
            });

            return data;
        }
    },
    methods : {
        getCurrentZoom() {
            if(window.innerWidth > 1500 ) {
                return 6;
            }else if(window.innerWidth > 1350) {
                return 5.7;
            }else if(window.innerWidth < 1350) {
                return 5.5;
            }
        },
        getFeaturePolygonByRegionId(region_id) {
            let feature = {};
            this.dataLayer.forEach(item => {
                if(+item.getProperty('region_id') === +region_id) {
                    feature = item;
                }
            });

            return Object.keys(feature).length ? feature : null;
        },
        regionMouseOver(region_id) {
            let feature = this.getFeaturePolygonByRegionId(region_id);
            if(feature) {
                this.triggerHoverRegion(feature);
            }
        },
        regionMouseOut(region_id) {
            let feature = this.getFeaturePolygonByRegionId(region_id);
            if(feature) {
                this.triggerHoverRegion(feature, false);
            }
        },
        getStatsByRegionId(region_id) {
            let stats = this.deputiesStats.find(item => {
                return +item.id === +region_id;
            });

            return stats ? stats : null;
        },
        async selectRegion(region_id) {
            if(this.is_district) {
                this.$emit('select_district', region_id);
                return false;
            }
            this.visiblePreloader = true;

            this.is_district = true;
            this.dataLayer.forEach(item => this.dataLayer.remove(item));
            this.init();

            markers.forEach(marker => {
                marker.setMap(null);
            });
            markers = [];

            let region = this.coordinates.find(region => {
                return +region.id === +region_id
            });

            this.deputiesStats = await DistrictService.deputiesCount(region_id);
            this.visibleSidebar = false;
            let s = await DistrictService.coordinates(region_id);
            this.regions = await DistrictService.get(region_id);
            this.zoom = 8;
            this.center = {
                lat: region.center[0],
                lng: region.center[1]
            };

            this.drawPolygons(this.prepareData(s));
        },
        init() {
            this.dataLayer = new this.google.maps.Data({
                map: this.map
            });

            this.infoWindow = new this.google.maps.InfoWindow();
        },
        getRegionColor(region_id) {
            let max = 0;
            this.deputiesStats.forEach(item => {
                if(max < +item.deputies_count) {
                    max = +item.deputies_count;
                }
            });

            let stats = this.getStatsByRegionId(region_id);

            if(!stats) {
                return '#cccccc80';
            }

            let percent = stats.deputies_count * 100 / max;
            percent = percent.toFixed(0);

            return `#204595${percent}`;
        },
        triggerHoverRegion(feature, hover = true) {
            feature.setProperty('hover', hover);
            this.map.data.revertStyle();
        },
        prepareData(coordinates) {
            let data = [];
            coordinates.forEach(coordinate => {
                let item = coordinate['polygon'];
                let type = 'Polygon';

                try {
                    if(item[0][0][0][0]) {
                        type = 'MultiPolygon';
                    }else if(!(item[0] && item[0][0] && item[0][0][0])) {
                        return false;
                    }
                }catch (e) {
                    return false;
                }

                data.push({
                    type: 'Feature',
                    geometry: {
                        type: 'GeometryCollection',
                        geometries: [{
                            type: type,
                            title: 'test',
                            coordinates: item
                        }]
                    },
                    properties: {
                        color: this.getRegionColor(coordinate['id']),
                        stats: this.getStatsByRegionId(coordinate['id']),
                        region_id: coordinate['id'],
                        center: coordinate['center']
                    }
                });
            });

            return data;
        },
        drawPolygons(features) {
            if(this.dataLayer) {
                this.dataLayer.addGeoJson({
                    type: "FeatureCollection",
                    features: features
                });

                this.dataLayer.setStyle(feature => {
                    let color = feature.getProperty('color') || '#cccccc80';
                    return {
                        fillColor: color,
                        fillOpacity: 1,
                        strokeWeight: feature.getProperty('hover') ? 1 : 0
                    }
                });

                this.dataLayer.addListener('mouseover', event => {
                    if(event.feature.getProperty('stats')) {
                        this.triggerHoverRegion(event.feature);
                    }
                });

                this.dataLayer.addListener('mouseout', event => {
                    if(event.feature.getProperty('stats')) {
                        this.triggerHoverRegion(event.feature, false);
                    }
                });

                this.dataLayer.addListener('click', event => {
                    if(event.feature.getProperty('stats')) {
                        this.selectRegion(event.feature.getProperty('region_id'));
                    }
                });

                this.setMarkers(features);
            }
        },
        setMarkers(features) {
            features.forEach(feature => {
                let label = this.getStatsByRegionId(feature['properties']['region_id']);
                if(!label) {
                    label = '';
                }else{
                    label = label.deputies_count;
                }

                if(!feature['properties']['center']) {
                    return false;
                }

                if(!label) {
                    label = 0;
                }

                let marker = new this.google.maps.Marker({
                    position: {
                        lat: feature['properties']['center'][this.is_district ? 1 : 0],
                        lng: feature['properties']['center'][this.is_district ? 0 : 1]
                    },
                    label: {
                        text: label + '',
                        color: "#f1f1f1",
                        fontSize: "16px"
                    },
                    map: this.map,
                    icon: {
                        path: this.google.maps.SymbolPath.CIRCLE,
                        scale: 18,
                        fillColor: '#1a274480',
                        strokeColor: '#1a2744',
                        fillOpacity: 1,
                        strokeWeight: 4,
                    }
                });

                marker.setMap(this.map);
                markers.push(marker);
                setTimeout(() => {
                    this.visiblePreloader = false;
                }, 1);
            });
        }
    },
}
</script>

<style scoped lang="less">
    .map{
        max-width: 1280px;
        width: 70%;
        height: calc(100vh - 36px);
        margin-top: 36px;
        position: relative;
    }
    .preloader-fade-enter-active, .preloader-fade-leave-active {
        transition: opacity .5s;
    }
    .preloader-fade-enter, .preloader-fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
        opacity: 0;
    }

    .sidebar-slide-enter-active, .sidebar-slide-leave-active {
        transition: transform .5s;
    }
    .sidebar-slide-enter, .sidebar-slide-leave-to /* .fade-leave-active до версии 2.1.8 */ {
        transform-origin: right center;
        transform: scaleX(0);
    }
    .preloader{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background-color: #f5f5f5;
        z-index: 10;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
    }
    .sidebar{
        position: absolute;
        top: 0;
        right: 0;
        width: 25%;
        height: 100%;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        overflow-y: auto;
        ul{
            list-style: none;
            padding: 40px;
            @media all and (max-width: 1500px) {
                padding: 20px;
            }
            background-color: #f5f5f5;
            li{
                margin-bottom: 10px;
            }
            button{
                color: #1a2744;
                text-decoration: underline;
                transition: color .15s linear;
                text-align: left;
                &:hover{
                    color: #ffc900;
                }
            }
            button, strong{
                font-size: 16px;
                @media all and (max-width: 1500px) {
                    font-size: 15px;
                }
                letter-spacing: -0.11px;
            }
            strong{
                margin-left: 5px;
                color: #ffc900;
            }
        }
    }
</style>
