import apiClient from "./ApiService";

export default {
    get(limit = null, offset = null) {
        return apiClient.get('council', { params: { limit: limit, offset: offset} })
    },
    getLevel() {
        return apiClient.get('council/levels')
    },
}
