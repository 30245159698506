<template>
    <!-- <h3>Картка</h3> -->
    <div class="deputy-card">
        <div class="deputy-card_top">
            <div v-if="user.photo" class="deputy-card_avatar">
                <img :src="user.photo" alt="avatar">
            </div>
            <h5 class="deputy-card_name">
                {{ fullName }}
            </h5>
        </div>
        <div class="deputy-card_list">
            <div
                class="deputy-card_item"
                v-for="item in items"
                :key="item.index">
                <span class="deputy-card_label">{{item.title}}</span>
                <span class="deputy-card_value">{{item.value}}</span>
            </div>
        </div>


        <div class="deputy-card_social">
            <a href="https://www.youtube.com/channel/UCjKn9aUDrZNUow49t43pY5Q?app=desktop"
               class="deputy-card_social-item" target="_blank">
                <svg width="30" height="21" viewBox="0 0 30 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M25.23 0.1725C27.0281 0.27375 27.9113 0.54 28.725 1.9875C29.5725 3.43313 30 5.92313 30 10.3088V10.3144V10.3237C30 14.6887 29.5725 17.1975 28.7269 18.6281C27.9131 20.0756 27.03 20.3381 25.2319 20.46C23.4338 20.5631 18.9169 20.625 15.0037 20.625C11.0831 20.625 6.56437 20.5631 4.76813 20.4581C2.97375 20.3362 2.09063 20.0737 1.26937 18.6262C0.43125 17.1956 0 14.6869 0 10.3219V10.3181V10.3125V10.3069C0 5.92313 0.43125 3.43313 1.26937 1.9875C2.09063 0.538125 2.97563 0.27375 4.77 0.170625C6.56437 0.050625 11.0831 0 15.0037 0C18.9169 0 23.4338 0.050625 25.23 0.1725ZM20.625 10.3125L11.25 4.6875V15.9375L20.625 10.3125Z"/>
                </svg>
            </a>
            <a href="https://twitter.com/euro_solidarity" class="deputy-card_social-item" target="_blank">
                <svg width="24" height="24" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                     xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                     viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve"><g><g><path d="M512,97.248c-19.04,8.352-39.328,13.888-60.48,16.576c21.76-12.992,38.368-33.408,46.176-58.016
                              c-20.288,12.096-42.688,20.64-66.56,25.408C411.872,60.704,384.416,48,354.464,48c-58.112,0-104.896,47.168-104.896,104.992
                              c0,8.32,0.704,16.32,2.432,23.936c-87.264-4.256-164.48-46.08-216.352-109.792c-9.056,15.712-14.368,33.696-14.368,53.056
                              c0,36.352,18.72,68.576,46.624,87.232c-16.864-0.32-33.408-5.216-47.424-12.928c0,0.32,0,0.736,0,1.152
                              c0,51.008,36.384,93.376,84.096,103.136c-8.544,2.336-17.856,3.456-27.52,3.456c-6.72,0-13.504-0.384-19.872-1.792
                              c13.6,41.568,52.192,72.128,98.08,73.12c-35.712,27.936-81.056,44.768-130.144,44.768c-8.608,0-16.864-0.384-25.12-1.44
                              C46.496,446.88,101.6,464,161.024,464c193.152,0,298.752-160,298.752-298.688c0-4.64-0.16-9.12-0.384-13.568
                              C480.224,136.96,497.728,118.496,512,97.248z"/></g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g></svg>
            </a>
            <a href="https://m.facebook.com/EuropeanSolidarity.official/" class="deputy-card_social-item"
               target="_blank">
                <svg width="14" height="26" viewBox="0 0 14 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M8.37885 26H3.80853C3.38751 26 3.04681 25.6593 3.04681 25.2383V15.2344H0.761658C0.34063 15.2344 -6.10352e-05 14.8937 -6.10352e-05 14.4727V9.90234C-6.10352e-05 9.48132 0.34063 9.14062 0.761658 9.14062H3.04681V5.33203C3.04681 2.39225 5.43907 0 8.37885 0H12.9492C13.3702 0 13.7109 0.340691 13.7109 0.761719V5.33203C13.7109 5.75306 13.3702 6.09375 12.9492 6.09375H9.14056V9.14062H12.9492C13.1731 9.14062 13.3858 9.23884 13.5301 9.40992C13.6751 9.581 13.7376 9.80642 13.7004 10.0273L12.9387 14.5976C12.8777 14.9651 12.5601 15.2344 12.1874 15.2344H9.14051V25.2383C9.14056 25.6593 8.79987 26 8.37885 26Z"/>
                </svg>
            </a>
        </div>
    </div>
</template>

<script>
    import UserService from "../services/UserService";

    const user_labels = {
        phone: 'Номер телефону'
    };
    export default {
        data() {
            return {
                user: {},
                items: []
            };
        },
        async created() {
            this.user = await UserService.getDeputy(this.$route.params.id);

            // let items = [];
            // for (const [key, value] of Object.entries(this.user)) {
            //     items.push({
            //         title: key,
            //         value: value
            //     })
            // }
            // console.log(items);
            this.items = items;
        },
        computed: {
            fullName() {
                return this.user.last_name + " " + this.user.first_name + " " + this.user.middle_name;
            }
        }
    };
</script>

<style scoped lang="less">
    .deputy-card {
        overflow-y: auto;
        background-color: #fff;
        width: 70%;
        padding: 80px;

        &_top {
            display: flex;
            align-items: center;
            padding: 20px 0;
            margin-bottom: 20px;
        }

        &_avatar {
            height: 70px;
            width: 70px;
            margin-right: 20px;

            img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                object-fit: cover;
            }
        }

        &_name {
            font-weight: normal;
            font-size: 18px;
            line-height: 24px;
            color: #1A2744;

            span {
                margin-right: 4px;
            }
        }

        &_item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #F5F5F5;
            padding: 20px 0;
        }

        &_list {
            margin-bottom: 47px;
        }

        &_label {
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            color: #9DA3AE;
        }

        &_value {
            font-weight: normal;
            font-size: 18px;
            line-height: 24px;
            color: #1A2744;
        }

        &_social {
            display: flex;

            &-item {
                height: 56px;
                width: 56px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;

                svg {
                    fill: #FFC900;
                }

                &:hover {
                    background: #FFC900;
                    box-shadow: 0px 24px 24px -14px rgba(249, 198, 35, 0.35);

                    svg {
                        fill: #fff;
                    }
                }
            }
        }
    }
</style>
