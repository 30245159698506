import apiClient from "./ApiService";

export default {
    get(type = [], parent_id = null) {
        return apiClient.get('region', {
            params: {
                typeIds: type.join(','), parentId: parent_id,
                all: true
            }
        })
    },
    coordinates() {
        return apiClient.get('region/coordinates');
    },
    deputiesCount() {
        return apiClient.get('region/deputies');
    }
}
