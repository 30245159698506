import apiClient from "./ApiService";

export default {
    get(region_id) {
        return apiClient.get('district', {params: {region_id: region_id}});
    },
    coordinates(region_id) {
        return apiClient.get('district/coordinates', {params: {region_id: region_id}});
    },
    deputiesCount(region_id) {
        return apiClient.get('district/deputies', {params: {region_id: region_id}});
    }
}
