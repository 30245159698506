<template>
    <main class="content">
        <div class="content-top">
            <!-- <h3 class="content_main-title">{{ totalCount }} результів</h3> -->
            <h5 class="content-description">Результати фільтрації: {{ totalCount }} </h5>
        </div>
        <div class="cards-items">
            <deputy-card v-for="(deputy) in deputies"
                 :user="deputy"
                 :cardParticipant="cardParticipant"
                 :key="deputy.id"
            />
            <observer :key="observerKey" @intersect="infiniteHandler"/>
        </div>
    </main>
</template>

<script>
    import DeputyCard from "./DeputyCard";
    import UserService from "../../services/UserService";
    import Observer from "../app/Observer";

    export default {
        name: "DeputyCards",
        components: {
            Observer,
            DeputyCard
        },
        data() {
            return {
                deputies: [],
                searchParams: [],
                totalCount: 0,
                scrollActive: true,
                offset: 0,
                observerKey: 0,
                limit: 40,
                cardParticipant: false,
            }
        },
        methods: {
            async searchDeputies(searchParams) {
                this.searchParams = searchParams;
                this.deputies = [];
                this.offset = 0;
                this.totalCount = 0;
                this.scrollActive = true;
                this.observerKey += 1; // force Vue to re-render observer component
            },
            async infiniteHandler()
            {
                if(!this.scrollActive) {
                    return;
                }

                let data;

                if (!this.cardParticipant){
                    data = await UserService.getDeputies(this.limit, this.offset, this.searchParams);
                }
                else if (this.cardParticipant){
                    data = await UserService.getParticipants(this.limit, this.offset, this.searchParams);
                }


                if (data.items.length) {
                    this.offset += this.limit;
                    this.totalCount = data.totalCount;
                    this.deputies = [...this.deputies, ...data.items];
                    this.scrollActive = data.items.length === this.limit;
                } else {
                    this.scrollActive = false
                }
            },

        },
        created() {
            if (this.$route.name === 'cards-participant'){
                this.cardParticipant = true
            }
        }

    }
</script>

<style scoped lang="less">

</style>
